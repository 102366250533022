<template>
  <div class="page-table mainDiv mb-30" id="affix-container">
    <div class="animated fadeInUp card-base baslikBg">
      Günlük Faaliyet Detayı
    </div>
    <el-row
      :gutter="10"
      v-loading="loading"
      element-loading-text="Müşteri Listesi Getiriliyor"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255, 255, 255, 1)"
    >
      <el-form
        status-icon
        :model="faaliyetForm"
        ref="faaliyetForm"
        class="demo-ruleForm faaliyet-form"
      >
        <el-col :lg="18" :md="24" :sm="24" :xs="24">
          <div
            class="card-base card-shadow--medium demo-box bg-white animated fadeInLeft pb-20 pt-10"
          >
            <el-row class="pdf-row" style="margin: 10px">
              <el-col
                :span="24"
                style="
                  background-color: red;
                  color: white;
                  text-align: center;
                  border: 1px solid black;
                  border-bottom: none;
                "
              >
                <h3>{{ faaliyetForm.faaliyetBaslik }}</h3>
              </el-col>
              <el-col
                :span="4"
                style="
                  background-color: #b5c0d0;
                  color: black;
                  border: 1px solid black;
                  border-right: none;
                  border-bottom: none;
                "
              >
                <p class="m-5 ml-5" style="font-weight: 700">İŞİN ADI</p>
              </el-col>
              <el-col
                :span="20"
                style="border: 1px solid black; border-bottom: none"
              >
                <p class="m-5 ml-5" style="font-weight: 600">
                  {{ faaliyetForm.faaliyetBaslik }}
                </p>
              </el-col>
              <el-col
                :span="4"
                style="
                  background-color: #b5c0d0;
                  color: black;
                  border: 1px solid black;
                  border-right: none;
                  border-bottom: none;
                "
              >
                <p class="m-5 ml-5" style="font-weight: 700">YÜKLENİCİ</p>
              </el-col>
              <el-col
                :span="20"
                style="border: 1px solid black; border-bottom: none"
              >
                <p class="m-5 ml-5" style="font-weight: 600">
                  BG GRUP İNŞAAT TAAHHÜT TURİZM DIŞ TİCARET SAN. LTD. ŞTİ.
                </p>
              </el-col>
              <el-col
                :span="4"
                style="
                  background-color: #b5c0d0;
                  color: black;
                  border: 1px solid black;
                  border-bottom: none;
                  border-right: none;
                "
              >
                <p class="m-5 ml-5" style="font-weight: 700">Hava Durumu</p>
              </el-col>
              <el-col
                :span="20"
                style="border: 1px solid black; border-bottom: none"
              >
                <p class="m-5 ml-5" style="font-weight: 600">AÇIK</p>
              </el-col>
              <el-col
                :span="4"
                style="
                  background-color: #b5c0d0;
                  color: black;
                  border: 1px solid black;
                  border-bottom: none;
                  border-right: none;
                "
              >
                <p class="m-5 ml-5" style="font-weight: 700">TARİH</p>
              </el-col>
              <el-col
                :span="20"
                style="border: 1px solid black; border-bottom: none"
              >
                <p class="m-5 ml-5" style="font-weight: 600">
                  {{ faaliyetForm.tarih }}
                </p>
              </el-col>
              <el-col
                :span="4"
                style="
                  background-color: #b5c0d0;
                  color: black;
                  border: 1px solid black;
                  border-right: 0;
                "
              >
                <p class="m-5 ml-5" style="font-weight: 700">GÜN</p>
              </el-col>
              <el-col :span="20" style="border: 1px solid black">
                <p class="m-5 ml-5" style="font-weight: 600">
                  {{ faaliyetForm.gun }}
                </p>
              </el-col>
              <el-col :span="12">
                <el-row>
                  <el-col
                    :span="15"
                    style="
                      background-color: #b5c0d0;
                      color: black;
                      border: 1px solid black;
                      border-top: none;
                      border-right: none;
                      border-bottom: none;
                    "
                  >
                    <p
                      class="m-5 ml-5"
                      style="
                        font-weight: 700;
                        text-align: center;
                        font-size: 15px;
                      "
                    >
                      TEKNİK PERSONEL
                    </p>
                  </el-col>
                  <el-col
                    :span="9"
                    style="
                      background-color: #b5c0d0;
                      color: black;
                      border: 1px solid black;
                      border-top: none;
                      border-right: none;
                      border-bottom: none;
                    "
                  >
                    <p
                      class="m-5 ml-5"
                      style="
                        font-weight: 700;
                        text-align: center;
                        font-size: 15px;
                      "
                    >
                      SAYISI
                    </p>
                  </el-col>
                  <el-col
                    v-for="item in personelData"
                    :key="item.teknikPersonelID"
                  >
                    <el-col
                      :span="15"
                      style="
                        border: 1px solid black;
                        border-bottom: none;
                        border-right: none;
                      "
                    >
                      <p class="m-5 ml-5">
                        {{ item.teknikPersonel }}
                      </p>
                    </el-col>
                    <el-col
                      :span="9"
                      style="
                        border: 1px solid black;
                        border-bottom: none;
                        border-right: none;
                      "
                    >
                      <p class="m-5 ml-5 text-center">
                        {{ item.sayi }}
                      </p>
                    </el-col>
                  </el-col>
                  <el-col>
                    <el-col
                      :span="15"
                      style="
                        background-color: #b5c0d0;
                        color: black;
                        text-align: center;
                        border: 1px solid black;
                        border-right: none;
                      "
                    >
                      <p class="m-5 ml-5">TOPLAM</p>
                    </el-col>
                    <el-col
                      :span="9"
                      style="border: 1px solid black; border-right: none"
                    >
                      <p class="m-5 ml-5 text-center font-weight-800">
                        {{ sumCount("personel") }}
                      </p>
                    </el-col>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="12">
                <el-row>
                  <el-col
                    :span="13"
                    style="
                      background-color: #b5c0d0;
                      color: black;
                      border: 1px solid black;
                      border-top: none;
                      border-right: none;
                    "
                  >
                    <p
                      class="m-5 ml-5"
                      style="
                        font-weight: 700;
                        text-align: center;
                        font-size: 15px;
                      "
                    >
                      MAKİNE EKİPMAN
                    </p>
                  </el-col>
                  <el-col
                    :span="8"
                    style="
                      background-color: #b5c0d0;
                      color: black;
                      border: 1px solid black;
                      border-top: none;
                      border-right: none;
                    "
                  >
                    <p
                      class="m-5 ml-5"
                      style="
                        font-weight: 700;
                        text-align: center;
                        font-size: 15px;
                      "
                    >
                      SAYI/BÖLÜM
                    </p>
                  </el-col>
                  <el-col
                    :span="3"
                    style="
                      background-color: #b5c0d0;
                      color: black;
                      border: 1px solid black;
                      border-top: none;
                    "
                  >
                    <p
                      class="m-5 ml-5"
                      style="
                        font-weight: 700;
                        text-align: center;
                        font-size: 15px;
                      "
                    >
                      SAAT
                    </p>
                  </el-col>
                  <el-col v-for="(item, i) in makineData" :key="item.makineID">
                    <el-col
                      :span="13"
                      style="
                        border: 1px solid black;
                        border-top: none;
                        border-right: none;
                      "
                      :style="{
                        borderBottom:
                          i ==
                          makineData.findIndex(
                            (item) =>
                              item.makineID ==
                              makineData[makineData.length - 1].makineID
                          )
                            ? 'none'
                            : '1px solid black',
                      }"
                    >
                      <p class="m-5 ml-5">
                        {{ item.makine }}
                      </p>
                    </el-col>
                    <el-col
                      :span="8"
                      style="
                        border: 1px solid black;
                        border-top: none;
                        border-right: none;
                      "
                      :style="{
                        borderBottom:
                          i ==
                          makineData.findIndex(
                            (item) =>
                              item.makineID ==
                              makineData[makineData.length - 1].makineID
                          )
                            ? 'none'
                            : '1px solid black',
                      }"
                    >
                      <p class="m-5 ml-5 text-center">
                        {{ item.sayi }}
                      </p>
                    </el-col>
                    <el-col
                      :span="3"
                      style="border: 1px solid black; border-top: none"
                      :style="{
                        borderBottom:
                          i ==
                          makineData.findIndex(
                            (item) =>
                              item.makineID ==
                              makineData[makineData.length - 1].makineID
                          )
                            ? 'none'
                            : '1px solid black',
                      }"
                    >
                      <p class="m-5 ml-5 text-center">
                        {{ item.saat || "&nbsp" }}
                      </p>
                    </el-col>
                  </el-col>
                </el-row>
              </el-col>
              <el-col
                :span="12"
                style="
                  border: 1px solid black;
                  border-right: none;
                  border-bottom: none;
                "
              >
                <el-row>
                  <el-col
                    :span="15"
                    style="
                      background-color: #b5c0d0;
                      color: black;
                      border-right: 1px solid black;
                    "
                  >
                    <p
                      class="m-5 ml-5"
                      style="
                        font-weight: 700;
                        text-align: center;
                        font-size: 15px;
                      "
                    >
                      DİĞER PERSONEL
                    </p>
                  </el-col>
                  <el-col
                    :span="9"
                    style="
                      background-color: #b5c0d0;
                      color: black;
                      border-right: 1px solid black;
                    "
                  >
                    <p
                      class="m-5 ml-5"
                      style="
                        font-weight: 700;
                        text-align: center;
                        font-size: 15px;
                      "
                    >
                      İŞÇİ SAYISI
                    </p>
                  </el-col>
                  <el-col
                    v-for="item in digerPersonelData"
                    :key="item.digerPersonelID"
                  >
                    <el-col
                      :span="15"
                      style="
                        border-top: 1px solid black;
                        border-right: 1px solid black;
                      "
                    >
                      <p class="m-5 ml-5">
                        {{ item.personel }}
                      </p>
                    </el-col>
                    <el-col
                      :span="9"
                      style="
                        border-top: 1px solid black;
                        border-right: none;
                        border-right: 1px solid black;
                      "
                    >
                      <p class="m-5 ml-5 text-center">
                        {{ item.sayi }}
                      </p>
                    </el-col>
                  </el-col>
                  <el-col>
                    <el-col
                      :span="15"
                      style="
                        background-color: #b5c0d0;
                        color: black;
                        text-align: center;
                        border: 1px solid black;
                        border-left: none;
                        border-bottom: none;
                      "
                    >
                      <p class="m-5 ml-5">TOPLAM</p>
                    </el-col>
                    <el-col
                      :span="9"
                      style="
                        border: 1px solid black;
                        border-bottom: none;
                        border-left: none;
                      "
                    >
                      <p class="m-5 ml-5 text-center font-weight-800">
                        {{ sumCount("digerPersonel") }}
                      </p>
                    </el-col>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="12">
                <el-row>
                  <el-col
                    :span="13"
                    style="
                      background-color: #b5c0d0;
                      color: black;
                      border: 1px solid black;
                      border-left: none;
                    "
                  >
                    <p
                      class="m-5 ml-5"
                      style="
                        font-weight: 700;
                        text-align: center;
                        font-size: 15px;
                      "
                    >
                      GELEN MALZEME
                    </p>
                  </el-col>
                  <el-col
                    :span="8"
                    style="
                      background-color: #b5c0d0;
                      color: black;
                      border: 1px solid black;
                      border-left: none;
                    "
                  >
                    <p
                      class="m-5 ml-5"
                      style="
                        font-weight: 700;
                        text-align: center;
                        font-size: 15px;
                      "
                    >
                      BÖLÜM
                    </p>
                  </el-col>
                  <el-col
                    :span="3"
                    style="
                      background-color: #b5c0d0;
                      color: black;
                      border: 1px solid black;
                      border-left: none;
                    "
                  >
                    <p
                      class="m-5 ml-5"
                      style="
                        font-weight: 700;
                        text-align: center;
                        font-size: 15px;
                      "
                    >
                      MİKTAR
                    </p>
                  </el-col>
                  <el-col v-for="item in malzemeData" :key="item.malzemeID">
                    <el-col
                      :span="13"
                      style="
                        border-right: 1px solid black;
                        border-bottom: 1px solid black;
                      "
                    >
                      <p class="m-5 ml-5">
                        {{ item.malzeme }}
                      </p>
                    </el-col>
                    <el-col
                      :span="8"
                      style="
                        border-right: 1px solid black;
                        border-bottom: 1px solid black;
                      "
                    >
                      <p class="m-5 ml-5 text-center">
                        {{ item.bolum }}
                      </p>
                    </el-col>
                    <el-col
                      :span="3"
                      style="
                        border-right: 1px solid black;
                        border-bottom: 1px solid black;
                      "
                    >
                      <p class="m-5 ml-5 text-center">
                        {{ item.miktar }}
                      </p>
                    </el-col>
                  </el-col>
                </el-row>
              </el-col>
              <el-col
                :span="24"
                style="
                  background-color: #b5c0d0;
                  color: black;
                  border: 1px solid black;
                  border-bottom: none;
                "
              >
                <h3 class="text-center m-5">GÜNLÜK İMALATLAR</h3>
              </el-col>
              <el-col
                :span="24"
                v-for="item in imalatData"
                :key="item.imalatID"
                style="border: 1px solid black; border-bottom: none"
              >
                <p class="m-0 ml-5 mb-5 mt-5">{{ item.imalat }}</p>
              </el-col>
              <el-col
                :span="12"
                style="
                  background-color: #b5c0d0;
                  color: black;
                  border: 1px solid black;
                  border-bottom: none;
                "
              >
                <h3 class="text-center m-5">HAZIRLAYAN</h3>
              </el-col>
              <el-col
                :span="12"
                style="
                  background-color: #b5c0d0;
                  color: black;
                  border: 1px solid black;
                  border-bottom: none;
                  border-left: none;
                "
              >
                <h3 class="text-center m-5">ŞANTİYE ŞEFİ</h3>
              </el-col>
              <el-col :span="12" style="border: 1px solid black">
                <p class="m-5 text-center font-weight-800" style="color: black">
                  {{ faaliyetForm.hazirlayan }}
                </p>
              </el-col>
              <el-col
                :span="12"
                style="border: 1px solid black; border-left: none"
              >
                <p class="m-5 text-center font-weight-800" style="color: black">
                  {{ faaliyetForm.santiyeSefi }}
                </p>
              </el-col>
            </el-row>
            <el-row class="imageListContainer">
              <div
                class="imageContainer"
                v-for="(url, index) in imageList"
                :key="index"
              >
                <img :src="url" />
              </div>
            </el-row>
            <vue-html2pdf
              :show-layout="false"
              :float-layout="true"
              :enable-download="true"
              :preview-modal="true"
              :paginate-elements-by-height="1200"
              :filename="faaliyetForm.faaliyetBaslik"
              :pdf-quality="5"
              :manual-pagination="false"
              pdf-format="a4"
              pdf-orientation="portrait"
              pdf-content-width="800px"
              @progress="onProgress($event)"
              ref="html2Pdf"
            >
              <section slot="pdf-content">
                <!-- PDF Content Here -->
                <el-row
                  class="content"
                  style="margin: 10px 15px 10px 10px !important"
                >
                  <el-col
                    :span="24"
                    style="
                      background-color: red;
                      color: white;
                      text-align: center;
                      border: 1px solid black;
                      border-bottom: none;
                      height: 50px;
                    "
                  >
                    <h3 style="width: 100%; text-align: center">
                      {{ faaliyetForm.faaliyetBaslik }}
                    </h3>
                  </el-col>
                  <el-col
                    :span="4"
                    style="
                      background-color: #b5c0d0;
                      color: black;
                      border: 1px solid black;
                      border-right: none;
                      border-bottom: none;
                    "
                  >
                    <p class="m-5 ml-5" style="font-weight: 700">İŞİN ADI</p>
                  </el-col>
                  <el-col
                    :span="20"
                    style="border: 1px solid black; border-bottom: none"
                  >
                    <p class="m-5 ml-5" style="font-weight: 600">
                      {{ faaliyetForm.faaliyetBaslik }}
                    </p>
                  </el-col>
                  <el-col
                    :span="4"
                    style="
                      background-color: #b5c0d0;
                      color: black;
                      border: 1px solid black;
                      border-right: none;
                      border-bottom: none;
                    "
                  >
                    <p class="m-5 ml-5" style="font-weight: 700">YÜKLENİCİ</p>
                  </el-col>
                  <el-col
                    :span="20"
                    style="border: 1px solid black; border-bottom: none"
                  >
                    <p class="m-5 ml-5" style="font-weight: 600">
                      BG GRUP İNŞAAT TAAHHÜT TURİZM DIŞ TİCARET SAN. LTD. ŞTİ.
                    </p>
                  </el-col>
                  <el-col
                    :span="4"
                    style="
                      background-color: #b5c0d0;
                      color: black;
                      border: 1px solid black;
                      border-bottom: none;
                      border-right: none;
                    "
                  >
                    <p class="m-5 ml-5" style="font-weight: 700">Hava Durumu</p>
                  </el-col>
                  <el-col
                    :span="20"
                    style="border: 1px solid black; border-bottom: none"
                  >
                    <p class="m-5 ml-5" style="font-weight: 600">AÇIK</p>
                  </el-col>
                  <el-col
                    :span="4"
                    style="
                      background-color: #b5c0d0;
                      color: black;
                      border: 1px solid black;
                      border-bottom: none;
                      border-right: none;
                    "
                  >
                    <p class="m-5 ml-5" style="font-weight: 700">TARİH</p>
                  </el-col>
                  <el-col
                    :span="20"
                    style="border: 1px solid black; border-bottom: none"
                  >
                    <p class="m-5 ml-5" style="font-weight: 600">
                      {{ faaliyetForm.tarih }}
                    </p>
                  </el-col>
                  <el-col
                    :span="4"
                    style="
                      background-color: #b5c0d0;
                      color: black;
                      border: 1px solid black;
                      border-right: 0;
                    "
                  >
                    <p class="m-5 ml-5" style="font-weight: 700">GÜN</p>
                  </el-col>
                  <el-col :span="20" style="border: 1px solid black">
                    <p class="m-5 ml-5" style="font-weight: 600">
                      {{ faaliyetForm.gun }}
                    </p>
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col
                        :span="15"
                        style="
                          background-color: #b5c0d0;
                          color: black;
                          border: 1px solid black;
                          border-top: none;
                          border-right: none;
                          border-bottom: none;
                        "
                      >
                        <p
                          class="m-5 ml-5"
                          style="
                            font-weight: 700;
                            text-align: center;
                            font-size: 15px;
                          "
                        >
                          TEKNİK PERSONEL
                        </p>
                      </el-col>
                      <el-col
                        :span="9"
                        style="
                          background-color: #b5c0d0;
                          color: black;
                          border: 1px solid black;
                          border-top: none;
                          border-right: none;
                          border-bottom: none;
                        "
                      >
                        <p
                          class="m-5 ml-5"
                          style="
                            font-weight: 700;
                            text-align: center;
                            font-size: 15px;
                          "
                        >
                          SAYISI
                        </p>
                      </el-col>
                      <el-col
                        v-for="item in personelData"
                        :key="item.teknikPersonelID"
                      >
                        <el-col
                          :span="15"
                          style="
                            border: 1px solid black;
                            border-bottom: none;
                            border-right: none;
                          "
                        >
                          <p class="m-5 ml-5">
                            {{ item.teknikPersonel }}
                          </p>
                        </el-col>
                        <el-col
                          :span="9"
                          style="
                            border: 1px solid black;
                            border-bottom: none;
                            border-right: none;
                          "
                        >
                          <p class="m-5 ml-5 text-center">
                            {{ item.sayi }}
                          </p>
                        </el-col>
                      </el-col>
                      <el-col>
                        <el-col
                          :span="15"
                          style="
                            background-color: #b5c0d0;
                            color: black;
                            text-align: center;
                            border: 1px solid black;
                            border-right: none;
                          "
                        >
                          <p class="m-5 ml-5">TOPLAM</p>
                        </el-col>
                        <el-col
                          :span="9"
                          style="border: 1px solid black; border-right: none"
                        >
                          <p class="m-5 ml-5 text-center font-weight-800">
                            {{ sumCount("personel") }}
                          </p>
                        </el-col>
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col
                        :span="13"
                        style="
                          background-color: #b5c0d0;
                          color: black;
                          border: 1px solid black;
                          border-top: none;
                          border-right: none;
                        "
                      >
                        <p
                          class="m-5 ml-5"
                          style="
                            font-weight: 700;
                            text-align: center;
                            font-size: 15px;
                          "
                        >
                          MAKİNE EKİPMAN
                        </p>
                      </el-col>
                      <el-col
                        :span="7"
                        style="
                          background-color: #b5c0d0;
                          color: black;
                          border: 1px solid black;
                          border-top: none;
                          border-right: none;
                        "
                      >
                        <p
                          class="m-5 ml-5"
                          style="
                            font-weight: 700;
                            text-align: center;
                            font-size: 15px;
                          "
                        >
                          SAYI/BÖLÜM
                        </p>
                      </el-col>
                      <el-col
                        :span="4"
                        style="
                          background-color: #b5c0d0;
                          color: black;
                          border: 1px solid black;
                          border-top: none;
                        "
                      >
                        <p
                          class="m-5 ml-5"
                          style="
                            font-weight: 700;
                            text-align: center;
                            font-size: 15px;
                          "
                        >
                          SAAT
                        </p>
                      </el-col>
                      <el-col
                        v-for="(item, i) in makineData"
                        :key="item.makineID"
                      >
                        <el-col
                          :span="13"
                          style="
                            border: 1px solid black;
                            border-top: none;
                            border-right: none;
                          "
                          :style="{
                            borderBottom:
                              i ==
                              makineData.findIndex(
                                (item) =>
                                  item.makineID ==
                                  makineData[makineData.length - 1].makineID
                              )
                                ? 'none'
                                : '1px solid black',
                          }"
                        >
                          <p class="m-5 ml-5">
                            {{ item.makine }}
                          </p>
                        </el-col>
                        <el-col
                          :span="7"
                          style="
                            border: 1px solid black;
                            border-top: none;
                            border-right: none;
                          "
                          :style="{
                            borderBottom:
                              i ==
                              makineData.findIndex(
                                (item) =>
                                  item.makineID ==
                                  makineData[makineData.length - 1].makineID
                              )
                                ? 'none'
                                : '1px solid black',
                          }"
                        >
                          <p class="m-5 ml-5 text-center">
                            {{ item.sayi }}
                          </p>
                        </el-col>
                        <el-col
                          :span="4"
                          style="border: 1px solid black; border-top: none"
                          :style="{
                            borderBottom:
                              i ==
                              makineData.findIndex(
                                (item) =>
                                  item.makineID ==
                                  makineData[makineData.length - 1].makineID
                              )
                                ? 'none'
                                : '1px solid black',
                          }"
                        >
                          <p class="m-5 ml-5 text-center">
                            {{ item.saat || "&nbsp" }}
                          </p>
                        </el-col>
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col
                    :span="12"
                    style="
                      border: 1px solid black;
                      border-right: none;
                      border-bottom: none;
                    "
                  >
                    <el-row>
                      <el-col
                        :span="15"
                        style="
                          background-color: #b5c0d0;
                          color: black;
                          border-right: 1px solid black;
                        "
                      >
                        <p
                          class="m-5 ml-5"
                          style="
                            font-weight: 700;
                            text-align: center;
                            font-size: 15px;
                          "
                        >
                          DİĞER PERSONEL
                        </p>
                      </el-col>
                      <el-col
                        :span="9"
                        style="
                          background-color: #b5c0d0;
                          color: black;
                          border-right: 1px solid black;
                        "
                      >
                        <p
                          class="m-5 ml-5"
                          style="
                            font-weight: 700;
                            text-align: center;
                            font-size: 15px;
                          "
                        >
                          İŞÇİ SAYISI
                        </p>
                      </el-col>
                      <el-col
                        v-for="item in digerPersonelData"
                        :key="item.digerPersonelID"
                      >
                        <el-col
                          :span="15"
                          style="
                            border-top: 1px solid black;
                            border-right: 1px solid black;
                          "
                        >
                          <p class="m-5 ml-5">
                            {{ item.personel }}
                          </p>
                        </el-col>
                        <el-col
                          :span="9"
                          style="
                            border-top: 1px solid black;
                            border-right: none;
                            border-right: 1px solid black;
                          "
                        >
                          <p class="m-5 ml-5 text-center">
                            {{ item.sayi }}
                          </p>
                        </el-col>
                      </el-col>
                      <el-col>
                        <el-col
                          :span="15"
                          style="
                            background-color: #b5c0d0;
                            color: black;
                            text-align: center;
                            border: 1px solid black;
                            border-left: none;
                            border-bottom: none;
                          "
                        >
                          <p class="m-5 ml-5">TOPLAM</p>
                        </el-col>
                        <el-col
                          :span="9"
                          style="
                            border: 1px solid black;
                            border-bottom: none;
                            border-left: none;
                          "
                        >
                          <p class="m-5 ml-5 text-center font-weight-800">
                            {{ sumCount("digerPersonel") }}
                          </p>
                        </el-col>
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col
                        :span="13"
                        style="
                          background-color: #b5c0d0;
                          color: black;
                          border: 1px solid black;
                          border-left: none;
                        "
                      >
                        <p
                          class="m-5 ml-5"
                          style="
                            font-weight: 700;
                            text-align: center;
                            font-size: 15px;
                          "
                        >
                          GELEN MALZEME
                        </p>
                      </el-col>
                      <el-col
                        :span="7"
                        style="
                          background-color: #b5c0d0;
                          color: black;
                          border: 1px solid black;
                          border-left: none;
                        "
                      >
                        <p
                          class="m-5 ml-5"
                          style="
                            font-weight: 700;
                            text-align: center;
                            font-size: 15px;
                          "
                        >
                          BÖLÜM
                        </p>
                      </el-col>
                      <el-col
                        :span="4"
                        style="
                          background-color: #b5c0d0;
                          color: black;
                          border: 1px solid black;
                          border-left: none;
                        "
                      >
                        <p
                          class="m-5 ml-5"
                          style="
                            font-weight: 700;
                            text-align: center;
                            font-size: 15px;
                          "
                        >
                          MİKTAR
                        </p>
                      </el-col>
                      <el-col v-for="item in malzemeData" :key="item.malzemeID">
                        <el-col
                          :span="13"
                          style="
                            border-right: 1px solid black;
                            border-bottom: 1px solid black;
                          "
                        >
                          <p class="m-5 ml-5">
                            {{ item.malzeme }}
                          </p>
                        </el-col>
                        <el-col
                          :span="7"
                          style="
                            border-right: 1px solid black;
                            border-bottom: 1px solid black;
                          "
                        >
                          <p class="m-5 ml-5 text-center">
                            {{ item.bolum }}
                          </p>
                        </el-col>
                        <el-col
                          :span="4"
                          style="
                            border-right: 1px solid black;
                            border-bottom: 1px solid black;
                          "
                        >
                          <p class="m-5 ml-5 text-center">
                            {{ item.miktar }}
                          </p>
                        </el-col>
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col
                    :span="24"
                    style="
                      background-color: #b5c0d0;
                      color: black;
                      border: 1px solid black;
                      border-bottom: none;
                    "
                  >
                    <h3 class="text-center m-5">GÜNLÜK İMALATLAR</h3>
                  </el-col>
                  <el-col
                    :span="24"
                    v-for="item in imalatData"
                    :key="item.imalatID"
                    style="border: 1px solid black; border-bottom: none"
                  >
                    <p class="m-0 ml-5 mb-5 mt-5">{{ item.imalat }}</p>
                  </el-col>
                  <el-col
                    :span="12"
                    style="
                      background-color: #b5c0d0;
                      color: black;
                      border: 1px solid black;
                      border-bottom: none;
                    "
                  >
                    <h3 class="text-center m-5">HAZIRLAYAN</h3>
                  </el-col>
                  <el-col
                    :span="12"
                    style="
                      background-color: #b5c0d0;
                      color: black;
                      border: 1px solid black;
                      border-bottom: none;
                      border-left: none;
                    "
                  >
                    <h3 class="text-center m-5">ŞANTİYE ŞEFİ</h3>
                  </el-col>
                  <el-col :span="12" style="border: 1px solid black">
                    <p
                      class="m-5 text-center font-weight-800"
                      style="color: black"
                    >
                      {{ faaliyetForm.hazirlayan }}
                    </p>
                  </el-col>
                  <el-col
                    :span="12"
                    style="border: 1px solid black; border-left: none"
                  >
                    <p
                      class="m-5 text-center font-weight-800"
                      style="color: black"
                    >
                      {{ faaliyetForm.santiyeSefi }}
                    </p>
                  </el-col>
                </el-row>

                <el-row class="imageListContainer">
                  <div
                    class="imageContainer"
                    v-for="(url, index) in imageList"
                    :key="index"
                  >
                    <img :src="url" />
                  </div>
                </el-row>
              </section>
            </vue-html2pdf>
          </div>
        </el-col>
        <el-col :lg="6" :md="24" :sm="24" :xs="24">
          <el-collapse
            class="yanForm animated fadeInUp"
            v-model="activeCollapse"
          >
            <el-collapse-item
              class="card-shadow--medium"
              name="2"
              title="İşlemler"
            >
              <el-row>
                <el-button
                  :loading="pdfGeneratorLoading"
                  @click="generateReport()"
                  class="onayBtn mt-10"
                  size="mini"
                  type="primary"
                  style="background-color: #2794eb; border-color: #2794eb"
                >
                  <label>PDF'e Dönüştür</label>
                </el-button>
                <el-button
                  @click="goUpdate('Faaliyet Güncelle')"
                  class="onayBtn mt-10"
                  size="mini"
                  type="primary"
                  style="background-color: #fba834; border-color: #fba834"
                >
                  <label>Düzenle</label>
                </el-button>
              </el-row>
              <el-row class="m-15">
                <el-divider />
                <p class="font-weight-600">Dosyalar</p>
                <el-upload
                  disabled
                  :file-list="faaliyetForm.dosyalar"
                  multiple
                  action=""
                  list-type="picture-card"
                  :auto-upload="false"
                  class="dosyalar"
                >
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{ file }">
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url"
                      alt=""
                    />
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-delete"
                        @click="fileDownload(file)"
                      >
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="Aç"
                          placement="bottom"
                        >
                          <i class="el-icon-download" />
                        </el-tooltip>
                      </span>
                    </span>
                  </div>
                </el-upload>
              </el-row>
            </el-collapse-item>
          </el-collapse>
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>
<script>
import notification from "../../../notification";
import functions from "../../../functions";
import EventBus from "@/components/event-bus";
import $ from "jquery";
import faaliyetService from "@/WSProvider/FaaliyetService";
import musteriService from "@/WSProvider/MusteriService";
import jwt_decode from "jwt-decode";
import _ from "lodash";
import VueHtml2pdf from "vue-html2pdf";

var moment = require("moment");
let time = moment().format("YYYY-MM-DD");

export default {
  name: "FaaliyetDetay",
  components: { VueHtml2pdf },
  mounted() {
    EventBus.$on("faaliyetDetay", () => {
      this.getFaaliyetDetay(this.faaliyetID);
    });
    this.getFaaliyetDetay(this.faaliyetID);
    this.getMusteriListesi();
  },
  computed: {
    faaliyetID() {
      return this.$store.getters.getFaaliyetID;
    },
    faaliyetStore() {
      return this.$store.getters.getFaaliyetData;
    },
    tatilList() {
      return this.$store.getters.getTatilList;
    },
  },
  created() {
    if (this.faaliyetStore.musteriID) {
      this.getProjeListesi(this.faaliyetStore.musteriID);
      this.faaliyetForm.musteriID = this.faaliyetStore.musteriID;
    }
    if (this.faaliyetStore.projeID) {
      this.faaliyetForm.projeID = this.faaliyetStore.projeID;
    }
  },
  watch: {
    "$store.state.faaliyetID": function (newVal) {
      this.getFaaliyetDetay(newVal);
    },
  },
  data() {
    return {
      imageList: [],
      pdfGeneratorLoading: false,
      projeler: [],
      musteriler: [],
      personelData: [],
      digerPersonelData: [],
      makineData: [],
      malzemeData: [],
      imalatData: [],
      loading: false,
      activeCollapse: ["1", "2"],
      visibleSil: false,
      faaliyetForm: {
        musteriID: "",
        projeID: "",
        faaliyetBaslik: "",
        havaDurumu: "",
        isDurumu: "",
        tarih: time,
        gun: "",
        hazirlayan:
          jwt_decode(localStorage.getItem("userDataBGSurec")).yoneticiAdSoyad ||
          "",
        santiyeSefi:
          jwt_decode(localStorage.getItem("userDataBGSurec")).yoneticiAdSoyad ||
          "",
        dosyalar: [],
      },
    };
  },
  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    onProgress(event) {
      this.pdfGeneratorLoading = true;
      setTimeout(() => {
        this.pdfGeneratorLoading = false;
      }, 2000);
    },
    goUpdate(name) {
      this.$store.commit("setFaaliyetID", this.faaliyetID);
      functions.routeSayfa(name, this);
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex % 2 === 0) {
          return {
            rowspan: 2,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
    sumCount(tur) {
      if (tur == "personel")
        return _.sumBy(this.personelData, function (o) {
          return parseInt(o.sayi);
        });
      if (tur == "digerPersonel")
        return _.sumBy(this.digerPersonelData, function (o) {
          return parseInt(o.sayi);
        });
    },
    getFaaliyetDetay() {
      const self = this;
      this.loading = true;
      faaliyetService
        .faaliyetDetay(this.faaliyetID)
        .then((response) => {
          localStorage.setItem("userDataBGSurec", response.token);
          if (response.status == 200) {
            this.getProjeListesi(response.data.musteriID);
            const faaliyetData = this.faaliyetForm;
            faaliyetData.musteriID = parseInt(response.data.musteriID);
            faaliyetData.projeID = parseInt(response.data.projeID);
            faaliyetData.faaliyetBaslik = response.data.faaliyetBaslik;
            faaliyetData.tarih = response.data.tarih;
            faaliyetData.gun = response.data.gun;
            faaliyetData.hazirlayan = response.data.hazirlayan;
            faaliyetData.santiyeSefi = response.data.santiyeSefi;
            faaliyetData.isDurumu = response.data.isDurumu;
            faaliyetData.havaDurumu = response.data.havaDurumu;
            faaliyetData.dosyalar = response.data.dosyalar.map((item) => {
              return { ...item, url: faaliyetService.path + item.dosya };
            });
            this.imageList = response.data.dosyalar.map((i) => {
              return faaliyetService.path + i.dosya;
            });
            $(".dosyalar .el-upload--picture-card").hide();
            if (response.data.digerPersonelData !== undefined)
              this.digerPersonelData = response.data.digerPersonelData.map(
                (item) => {
                  return { ...item, id: item.digerPersonelID };
                }
              );
            if (response.data.makineData !== undefined)
              this.makineData = response.data.makineData.map((item) => {
                return { ...item, id: item.makineID };
              });
            if (response.data.imalatData !== undefined)
              this.imalatData = response.data.imalatData.map((item) => {
                return { ...item, id: item.imalatID };
              });
            if (response.data.personelData !== undefined)
              this.personelData = response.data.personelData.map((item) => {
                return { ...item, id: item.teknikPersonelID };
              });
            if (response.data.malzemeData !== undefined)
              this.malzemeData = response.data.malzemeData.map((item) => {
                return { ...item, id: item.malzemeID };
              });
          }
        })
        .catch((err) => {
          if (err.responseJSON) {
            let error = err.responseJSON;
            if (error.errMsg)
              notification.Status("errorMsg", this, error.errMsg);
            if (error.status == 401) {
              notification.Status(150, this);
            }
          }
        })
        .finally(() => (self.loading = false));
    },
    fileDownload(file) {
      window.open(file.url, "_blank");
    },
    getProjeListesi(val) {
      try {
        this.faaliyetForm.projeID = "";
        musteriService
          .musteriProjeleri("", "", val || this.faaliyetForm.musteriID, 1)
          .then((response) => {
            localStorage.setItem("userDataBGSurec", response.token);
            if (response.count && response.count != 0) {
              this.projeler = response.data;
            }
          })
          .catch((err) => {
            if (err.responseJSON) {
              let error = err.responseJSON;
              if (error.status == 401) {
                notification.Status(150, this);
              } else if (error.status == 404) {
                this.projeler = [];
              } else if (error.status == 503) {
                this.projeler = [];
                notification.Status(
                  "warning",
                  this,
                  "Bu müşterinin projesi bulunmamaktadır."
                );
              } else notification.Status("errorMsg", this, error.errMsg);
            } else {
              //notification.Status(503, this)
            }
          });
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.projeLoading = false;
      }
    },
    filterMethod(query, item) {
      return item.label.toLowerCase().indexOf(query.toLowerCase()) >= 0;
    },
    getMusteriListesi() {
      try {
        this.loading = true;
        musteriService
          .musteriListesi(1, 0, 10000)
          .then((response) => {
            localStorage.setItem("userDataBGSurec", response.token);
            if (response.status == 200) {
              this.musteriler = response.data;
            }
            this.loading = false;
          })
          .catch((err) => {
            if (err.responseJSON) {
              let error = err.responseJSON;
              if (error.status == 401) {
                notification.Status(150, this);
              } else if (error.status == 404) {
                this.musteriler = [];
              } else notification.Status("errorMsg", this, error.errMsg);
            } else {
              //notification.Status(503, this)
            }
            this.loading = false;
          });
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.loading = false;
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.visibleSil = false;
      this.faaliyetForm.havaDurumu = "";
      this.faaliyetForm.dosyalar = [];
      this.projeler = [];
      this.musteriler = [];
      this.personelData = [];
      this.digerPersonelData = [];
      this.makineData = [];
      this.malzemeData = [];
      this.imalatData = [];
    },
  },
  beforeDestroy() {
    this.$store.commit("setFaaliyetData");
    window.removeEventListener("resize", this.checkDevice);
  },
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 500px) {
  .pdf-row {
    zoom: 0.5;
  }
}

.faaliyet-form {
  .faaliyet-first-divider {
    margin-top: 0 !important;
  }

  .faaliyet-dosya {
    float: right;
  }

  @media screen and (max-width: 500px) {
    .faaliyet-dosya {
      float: left;
    }
    .makine-saat-text {
      text-align: center;
    }
    .makine-saat-input {
      width: 80px;
      float: right;
    }
    .el-button--mini.is-circle {
      padding: 5px;
    }
    .el-input-number--mini {
      width: 110px !important;
    }
  }

  .el-divider--horizontal {
    margin: 12px 0;
  }

  .faaliyet-card-title {
    font-weight: 700;
    font-size: 15px;
  }

  .imalatlar-data-card {
    .input-row .el-col:nth-child(2) .el-input {
      width: 90% !important;
    }
  }

  .malzeme-data-card {
    .input-row .el-col:nth-child(2) .el-input {
      width: 90% !important;
    }

    .input-row .el-col:nth-child(3) .el-input {
      width: 90% !important;
    }
  }

  .makine-data-card {
    .input-row .el-col:nth-child(2) .el-input {
      width: 90% !important;
    }
  }

  .personel-data-card {
    .input-row .el-col:nth-child(2) .el-input {
      width: 90% !important;
    }
  }

  .diger-personel-data-card {
    .input-row .el-col:nth-child(2) .el-input {
      width: 90% !important;
    }
  }

  .table-first-column {
    display: contents;
  }

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }
}
</style>
<style lang="scss">
.faaliyet-form {
  .el-table_1_column_1 {
    text-align: center;
  }

  .el-table_2_column_3 {
    text-align: center;
  }
}

.imageListContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
  flex-wrap: wrap;
  margin: 0 20px 0 10px;
  .imageContainer {
    border: 1px solid grey;
    width: 100%;
    height: 530px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
